import styled from "styled-components";

export const CarouselContainer = styled.div`
    min-height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #010606;
    @media screen and (max-width: 768px) {
        min-height: 400px;
    }

    @media screen and (max-width: 480px) {
        min-height: 300px;
    }
`

export const CarouselWrapper = styled.div`
    width: 100%;
    height: 100%;
`