import React from "react";
import {
  CarouselContainer,
  CarouselWrapper
} from "./CarouselElements";

import ImageGallery from "react-image-gallery";
const images = [
  {
    original: "https://api.jdwatt.fr/outimg/1.jpg",
    thumbnail: "https://api.jdwatt.fr/outimg/1.jpg",
  },
  {
    original: "https://api.jdwatt.fr/outimg/2.jpg",
    thumbnail: "https://api.jdwatt.fr/outimg/2.jpg",
  },
  {
    original: "https://api.jdwatt.fr/outimg/3.jpg",
    thumbnail: "https://api.jdwatt.fr/outimg/3.jpg",
  },
  {
    original: "https://api.jdwatt.fr/outimg/4.jpg",
    thumbnail: "https://api.jdwatt.fr/outimg/4.jpg",
  },
  {
    original: "https://api.jdwatt.fr/outimg/5.jpg",
    thumbnail: "https://api.jdwatt.fr/outimg/5.jpg",
  },
];

const Carousel = () => {
  return (
    <CarouselContainer id="instagramCarousel">
      <CarouselWrapper>
        <ImageGallery
          items={images}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
          showBullets={true}
        />
      </CarouselWrapper>
    </CarouselContainer>
  );
};

export default Carousel;
