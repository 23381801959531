import styled from "styled-components";

export const FooterContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: #000; 
`;

export const FooterWrapper = styled.div`
    max-width: 900px;


`

export const FooterH2 = styled.h2`
    font-size: 1.1rem;
    color: grey;
    font-weight: 100;
    margin-bottom: 20px;

`

export const FooterLink = styled.a`
    font-size: 0.9rem;
    text-decoration: none;
    color: white;
    display: flex;
    transition: all 0.3s;

    &:hover {
        color: #FFD800;
    }

`