import styled from "styled-components";

export const AboutContainer = styled.div`
    width: 100%;
    min-height: 700px;
    justify-content: center;
    display: flex;
    background: #010606;
`;

export const AboutWrapper = styled.div `
    display: flex;
    padding: 80px 0;
    width: 1200px;
    justify-content: center;
    align-items: center;

`

export const Title = styled.h1 `
    font-weight: 900;

`

export const Subtitle = styled.h5`
    color: #ffd800;
    font-weight: 500;
    text-transform: uppercase;
`;