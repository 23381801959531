import React, { useState } from "react";
import { Sidebar } from "./../components/sidebar";
import { Navbar } from "./../components/navbar";
import HeroSection from "../components/heroSection";
import About from "../components/about";
import Services from "../components/services";
import Carousel from "../components/Carousel";
import Contact from "../components/contact";
import Footer from "../components/footer";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <About />
      <Services />
      <Carousel />
      <Contact />
      <Footer />
    </>
  );
};
