import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { ContactContainer, ContactWrapper, ContactH1, Subtitle, Title, Paragraph } from "./ContactElements";

export const Contact = () => {
  return (
    <>
      <ContactContainer id="contact">
        <ContactWrapper>
          <ContactH1 className="mb-5">Contact</ContactH1>
          <p className="px-4 pb-5">
            Vous avez une question ? Besoin d'un devis ? N'hésitez pas à nous
            contacter avec les informations présentes ci-dessous :
          </p>
          <div className="row expand-row gx-5 text-center">
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>Par mail :</Subtitle>
              <Title className="mb-5">
                <a href="mailto:jdwatt@gmail.com">jdwatt@gmail.com</a>
              </Title>
            </div>
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>Par téléphone :</Subtitle>
              <Title className="mb-5">
                <a href="tel:0618381575">06 18 38 15 75</a>
              </Title>
              <Paragraph>
                <i>Du Lundi au Samedi, 8h00 à 18h30</i>
              </Paragraph>
            </div>
          </div>
        </ContactWrapper>
      </ContactContainer>
    </>
  );
};

export default Contact;
