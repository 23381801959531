import React from "react";
import {
  FooterContainer,
  FooterH2,
} from "./FooterElements";
import "./../../App.css";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterH2 className="logo-text gray-text center-align">jdwatt - Tous droits reservés</FooterH2>
    </FooterContainer>
  );
};

export default Footer;
