import styled from "styled-components";

export const ContactContainer = styled.div `
    width: 100%;
    min-height: 540px;    
    justify-content: center;
    align-items: center;
    display: flex;

    @media screen and (max-width: 768px) {
      margin-top: -40px;
`

export const ContactWrapper = styled.div`
    display: flex;
    padding: 80px 0;
    width: 1200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 100px 0;
    }
`;

export const ContactH1 = styled.h1`
    font-size: 2.5rem;
    margin-bottom: 64px;
    text-decoration: none;
    color: #010606;

    @media screen and (max-width: 768px) {
      padding-bottom: 20px;
    }

    @media screen and (max-width: 480px) {
      font-size: 2rem;
    }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 64px;
  text-decoration: none;
  font-weight: 900;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }

  a {
    color: #010606;
    transition: all 0.2s ease-in-out;
  }

  a:link {
    color: #010606;
    background-color: transparent;
    text-decoration: none;
  }

  a:visited {
    color: #010606;
    background-color: transparent;
    text-decoration: none;
  }

  a:hover {
    color: #525252;
    background-color: transparent;
    text-decoration: none;
  }
`;

export const Subtitle = styled.h5`
    color: #ffcd00;
    font-weight: 500;
    text-transform: uppercase;
`;

export const Paragraph = styled.p`
  margin: -20px 0;
`;