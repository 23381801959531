import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";
import Icon1 from "../../images/services/bolt.svg"
import Icon2 from "../../images/services/ethernet.svg";
import Icon3 from "../../images/services/fixing.svg";
import Icon4 from "../../images/services/ev-charger.svg";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Nos Prestations</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Electricité générale</ServicesH2>
          <ServicesP>
            Nous réalisons tous vos travaux d’electricité générale sur n'importe
            quel type d’installation. <i>(neuves, à renover)</i>
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Réseaux ethernet & basses tensions</ServicesH2>
          <ServicesP>
            Nous réalisons également vos cablages ethernet et vos installations
            basses tension.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Dépannage</ServicesH2>
          <ServicesP>
            Nous réalisons également tout les travaux de dépannage et de
            maintenance éléctrique.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Bornes véhicules électriques</ServicesH2>
          <ServicesP>
            Nous réalisons toutes vos installations de bornes de recharge pour véhicules électriques.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
