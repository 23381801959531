import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  HeroPicture,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { ButtonR } from "../ButtonElements";
import "../../App.css";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="heroSection">
      <HeroBg>
        <HeroPicture />
      </HeroBg>
      <HeroContent>
        <h1 className="h1-hero">Electricité générale à Vaupillon</h1>
        <p className="hero-text">
          Demandez dès aujourd'hui votre devis gratuit pour tout vos
          travaux.
        </p>
        <HeroBtnWrapper>
          <ButtonR
            to="about"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            En savoir plus {hover ? <ArrowForward /> : <ArrowRight />}
          </ButtonR>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
