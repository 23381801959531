import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { AboutContainer, AboutWrapper, Subtitle, Title } from "./AboutElements";

import image from "../../images/truckytruck.svg"

export const About = () => {
  return (
    <>
      <AboutContainer id="about">
        <AboutWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>A propos</Subtitle>
              <Title className="text-white mb-5">
                L'électricien adapté à vos besoins
              </Title>
              <p className="text-white mb-4">
                Vous avez besoin d'un électricien qualifié pour votre maison ou
                votre entreprise ? <br></br>Ne cherchez plus ! <br></br>JDWatt
                est prêt à répondre à tous vos besoins électriques. Que ce soit
                pour une réparation rapide, une mise à niveau de votre système
                électrique ou pour une installation complète, nous avons
                l'expertise pour vous aider. Nous engageons à vous offrir un
                service de qualité à un prix abordable. <br></br>Nous opérons
                dans le 28, dans les environs de Vaupillon. <br></br>
                <i>(La Loupe, Chartres, Courville, etc.)</i>
              </p>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <img src={image} alt="" className="fit-img-worker"></img>
            </div>
          </div>
        </AboutWrapper>
      </AboutContainer>
    </>
  );
};

export default About;
